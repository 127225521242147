import * as React from "react"
import Sidebar from "@components/vertical-sidebar/VerticalSidebar"
import BuildingManagerAdd from "@components/building-managers/Add"
import Seo from "@components/seo"
import "../../styles/index.scss"

const AdditionalParams = {
  endPointName: "building-managers.create"
}

const BuildingManagerAddPage = (props) => {
  return (
  <>
    <Seo title="Add Building Manager" />
      <div className="main-wrapper">
        <Sidebar menuActive="building-managers" 
          action="Add"/>
        <BuildingManagerAdd 
          menu="Add Building Managers" 
          parentPath="building-managers" 
          pageType="Add"
          additionalParams={AdditionalParams}/>
      </div>
    </>
  )
}

export default BuildingManagerAddPage;
